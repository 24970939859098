<template>
  <div class="sub-cont">
    <div class="mypage-wrap" style="padding-bottom: 0">
      <h2 class="h2-mypage">자격증</h2>
      <button class="btn __add" @click="addForm">
        <i class="el-icon-plus"></i> 추가
      </button>
      <div class="empty_form" v-if="isCertEmpty">자격증을 추가하세요.</div>
      <div
        v-for="(cert, ix) in certs"
        :key="`ca_${ix}`"
        class="input-form-wrap"
      >
        <div class="input-form">
          <div class="t-head">자격증명</div>
          <div class="t-doc">
            <input
              type="text"
              v-model="cert.certName"
              class="text"
              :ref="`certName_${ix}`"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">발급기관</div>
          <input
            type="text"
            v-model="cert.issuer"
            class="text issuing"
            :ref="`issuer_${ix}`"
          />
        </div>
        <div class="input-form">
          <div class="t-head">발행일자</div>
          <input
            type="date"
            v-model="cert.issueDate"
            class="inp datepicker"
            :id="`issueDate_${ix}`"
            autocomplete="off"
            inputmode="none"
            :ref="`issueDate_${ix}`"
          />
        </div>
        <div class="input-form">
          <div class="t-head">일련번호</div>
          <div class="t-doc end">
            <input
              type="text"
              v-model="cert.serialNo"
              class="text"
              :ref="`serialNo_${ix}`"
            />
          </div>
        </div>
        <div class="btn_box">
          <button class="btn __delete" @click="deleteCert(ix)">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCertList, saveCertInfo } from 'Api/modules'
import { mapGetters } from 'vuex'
import resumeMixin from 'Mixins/resume'
import datePickerMixin from 'Mixins/datePicker'

export default {
  mixins: [resumeMixin, datePickerMixin],
  data() {
    return {
      certs: [
        {
          certName: '',
          issuer: '',
          issueDate: '',
          serialNo: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['account']),
    isCertEmpty() {
      return this.certs.length == 0
    },
  },
  methods: {
    async save() {
      console.info('certs save', this.certs)
      //입력 오류
      if (!this.checkValues(this.certs)) return false

      try {
        // const res = await saveCertInfo({ certs: this.certs })
        await saveCertInfo({ certs: this.certs })
        // alert(res.message)
        this.$toasted.show('자격증이 등록되었습니다.')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    deleteCert(idx) {
      this.deleteItemOrClearForm(this.certs, idx)
    },
    addForm() {
      this.certs.push({
        certName: '',
        issuer: '',
        issueDate: '',
        serialNo: '',
      })
    },
  },
  async created() {
    // 자격증 목록 조회
    try {
      const res = await getCertList({ userId: this.account.userId })
      if (res && res.length > 0) {
        this.certs = res
      }
      console.info('getCertList', res)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
}
</script>

<style>
.empty_form {
  padding-top: 90px;
  height: 160px;
  border: 1px;
  border-style: none none solid none;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
</style>
